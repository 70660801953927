@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";

.pats-orders-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mobileThemeHeaderBarColor;
  padding: 0px 16px 0px 16px;
  height: 100%;
  width: 100%;

  .back-button {
    width: fit-content;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0;

    .back-icon {
      color: $white;
      margin: 0 auto;
    }
  }

  .title-section {
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    width: 100%;

    .title {
      color: $white;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      position: relative;
      right: 12px;
      width: 188px;
    }
  }
}

.progress-bar-wrapper {
  display: flex;
  margin: 24px 17px;
  column-gap: 4px;

  .bar {
    border-radius: 4px;
    height: 4px;
    background-color: $gray;
  }

  .active {
    background-color: $mobileThemeExtraLightColor;
  }
}

.pats-order-form-body-wrapper {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding-inline: 16px;
  padding-top: 2px;
  .pats-order-form-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    position: relative;
    overflow: auto;
    .pats-order-form-content {
      height: 100%;
      position: relative;
      overflow-y: auto;
    }
  }

  .patient-form-buttons-container {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 40px;
  }
}
