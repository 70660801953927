@import "../../assets/stylesheets/variables.scss";

.attachment-row {
  // border-left: 1px solid #c9c9c9;
  margin: 5px 10px 0 0;
  width: auto;
  // float: left;
  // padding-left: 10px;
  
  .attachment-name {
    margin: 0 10px;
    color:$primaryTextColor;
    line-height: 30px;
    @media screen and (max-width: 572px) {
      max-width: 245px;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      float: left;
    }

    @media screen and (max-width: 320px) {
      max-width: 200px;
    }
  }

  .remove-attachment-icon {
    padding-top: 2px;
  }

  .attachment-icon {
    line-height: 30px;
    display: inline-block;
    float: left;
    .icon::before {
      top: 4px;
      font-size: 20px;
    }
  }
  
  .remove-attachment-container {
    border: 1px solid $primaryTextColor;
    border-radius: 15px;
    width: auto;
    display: inline-block;
    color: $primaryTextColor;

    >span {
      margin-left: 10px;
    }
    .remove-icon {
      float: right;
      margin-right: 10px;
      // margin-top: 2px;

      &:before {
        font-size: 12px;
        top: 2px;
      }
    }
  }
}

// .separation-0 {
//   border: 0;
//   padding-left: 0;
// }

