@import "../../assets/stylesheets/variables.scss";
@import "../../assets/stylesheets/fonts.scss";
.form-control {
  height: 30px;
  font-size: 14px;
  padding: 5px;
  &:focus {
    box-shadow: none;
  }
}

select.form-control {
  background: url('../images/down_chevron_filled_gray.png') no-repeat right 5px center;
  background-size: 12px;
}

label {
  font-weight: 500;
  margin-bottom: 5px;
}

.btn-primary {
  background-color: $btnPrimaryColor;
  border: 1px solid $btnPrimaryColor;
  height: 35px;
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    background-color: $defaultBtnHoverColor;
    border: 1px solid $defaultBtnHoverColor;
    .icon:before {
      color: white;
    }
  }
}

.btn-default,
.btn-default:focus {
  height: 35px;
  border: 1px solid $btnPrimaryColor;
  color: $btnPrimaryColor;
  &.red {
    border: 1px solid $red;
    color: $red;
  }
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  background: white;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    border: 1px solid $btnPrimaryColor;
    .icon:before {
      color: white;
    }
  }
}

.btn-danger,
.btn-danger:focus {
  height: 35px;
  border: 1px solid $red;
  color: $red;
  &.red {
    border: 1px solid $red;
    color: $red;
  }
  border-radius: 20px;
  padding: 0px 25px;
  font-weight: 300;
  font-size: 16px;
  background: white;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    height: 30px;
  }
  &:hover {
    border: 1px solid $red;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: $red !important;
    &.red {
      background-color: $red !important;
      border: 1px solid $red;
    }
    color: #ffffff !important;
    border: 1px solid $red;
  }
}

.btn-primary:active,
.btn-primary:focus {
  background-color: $defaultBtnHoverColor !important;
  &.red {
    background-color: $red !important;
    border: 1px solid $red;
  }
  color: #ffffff !important;
  border: 1px solid $defaultBtnHoverColor;
}

.btn-default:hover,
.btn-default:active {
  background-color: $primaryTextColor !important;
  &.red {
    background-color: $red !important;
    border: 1px solid $red;
  }
  color: #ffffff !important;
  border: 1px solid $primaryTextColor;
}

.btn-default-compressed {
  height: 30px;
  border: 1px solid $primaryTextColor;
  color: $primaryTextColor;
  border-radius: 20px;
  padding: 0px 20px;
  font-weight: 300;
  font-size: 14px;
  background: white;
}

.btn-default-compressed:hover,
.btn-default-compressed:active,
.btn-default-compressed:focus {
  background-color: $primaryTextColor !important;
  &.red {
    background-color: $red !important;
    border: 1px solid $red;
  }
  color: #ffffff !important;
  border: 1px solid $primaryTextColor;
}

* {
  font-family: 'MuseoSans', sans-serif;
}

.btn-default-compressed[disabled]:hover,
.btn-default-compressed[disabled] {
  background-color: white !important;
  border: 1px solid $lightGray !important;
  color: $gray !important;
}

.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover {
  background-color: $lightGray !important;
  border: 1px solid $lightGray !important;
}

.btn.btn-default[disabled],
.btn.btn-default[disabled]:hover {
  background-color: white !important;
  border: 1px solid $gray !important;
  color: $gray !important;
  .icon:before {
    color: $gray;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-weight: 300;
  &:focus {
    outline: 0 !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: none;
}

.navbar-default {
  background-color: #fff;
  border-color: #e7e7e7;
}

.badge {
  margin-left: 5px !important;
  font-weight: 100;
 &.orange {
  background-color: $orangeColor;
 }
 &.red{
  background-color: red !important;
  color: white !important;
 }
}
