@import "../../../assets/stylesheets/variables.scss";

.single-select-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .placeholder-text {
    text-overflow: ellipsis !important;
    color: #999;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    border-radius: "6px";
  }
  .input-label {
    display: inline;
  }
  .required:before {
    content: " *";
    color: $mobileDangerColor;
    display: inline;
  }
}
