@import "../../assets/stylesheets/variables";

.outcomes-sharing-form-wrapper *, .hcp-report-sharing-modal *, .pop-up-wrapper * {
  font-family: 'SF Pro Text' !important;
}

.mobile-navigation-bar {
  display: grid;
  margin-top: -60px;
  padding-inline: 10px;
  grid-template-columns: 1fr 8fr 1fr;
  place-items: center;
  user-select: none;
  -webkit-user-select: none;

  h5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'SF Pro Text' !important;
  }
}

.outcomes-sharing-form-wrapper {
  height: calc(100vh - 56px);
  background-color: $slightGray;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;

  .report-recipients-wrapper {
    height: 100%;
    .filters-container {
      background-color: $slightGray;
      display: flex;
      flex-direction: column;
      padding-inline: 36px;
      row-gap: 20px;
      height: 100%;

      .report-modal-button {
        box-shadow: rgba(0, 0, 0, 0.1) -2px 3px 2px 2px;
      }

      .report-filter {
        .multi-select-container {
          font-size: 12px;
        }
      }
    }

    .report-date-picker {

      .date-field {
        border: 1px solid $hcpReportGray !important;
        border-radius: 4px;
        padding-inline: 5px;
        height: 46px;

        .rdtPicker {
          left: 0;
          width: 100%;
        }

        input {
          background-image: url("../../assets/images/ic_mobile_calender.png") !important;
          height: auto;
          margin-top: 1px;
          font-size: 14px !important;
        }
      }
    }

    .select-clear-icon.icon::before {
      color: $reportLabelColor;
      font-size: 20px;
    }

    .multi-select__control {
      padding: 8px;
    }
  }

  .report-filter {
    .field-label {
      color: $reportLabelColor;
      font-size: 14px;
      line-height: 24px;
    }

    .multi-select {
      &__control {
        border: 1px solid $hcpReportGray !important;
      }

      &__dropdown-indicator {
        color: $begulingBlue !important;
      }
    }
  }

  .selected-accounts-list-wrapper {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;

    .selected-accounts-header {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 2px 0;
    }

    .selected-account-list-item {
      line-height: 18px;
      color: rgba(60, 60, 67, 0.6);
    }

    .selected-accounts-list p {
      margin: 0 0 4px 0;

      .selected-accounts-list-more-items {
        color: $deepBlue; 
        font-weight: 600;
      }
    }
  }

  .report-modal-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .review-selection-button {
      margin-top: 44px;
    }
  }

  .custom-dropdown-wrapper  {
    .custom-dropdown-wrapper-filters{
      display: flex;
      flex-direction: column;
      gap: 28px;
  
      .field-control {
        &__input {
          padding: 8px;
          border: 1px solid $hcpReportGray !important;
        }
        position: relative;

        .field-label {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $reportLabelColor;
        }
      }
      .icon-font-a-clear-all {
        color: $begulingBlue;
        position: absolute;
        right: 40px;
        top: 38px;
        z-index: 2;

        &::before {
          font-weight: bolder !important;
          font-size: 16px !important;
        }
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }
}

.preview-screen-wrapper {

  margin-top: -60px;

  .preview-screen-header {
    border-bottom: 1px solid $hcpReportGray;
    display: flex;
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    place-items: center;

    h5 {
      flex: 2;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.report-modal-button {
  background: $hcpReportButton;
  border: 1px solid $hcpReportButton;
  border-radius: 61px;
  color: $white;
  max-width: 343px;
  width: 100%;
  padding: 12px 0;
  margin-top: 22px;
  font-size: 18px;
  line-height: 21px;

  &:hover, &:focus {
    border: 1px solid $hcpReportButtonBorder;
  }
}

.report-modal-hollow-button {
  color: $hcpReportButton;
  background-color: $hcpReportSecondaryButton;
  border-color: $hcpReportButtonBorder;
}

.review-selection-wrapper {
  .selected-filter-overview-wrapper {
    margin-bottom: 10px;

    .review-selection-filter-title {
      font-weight: 400 !important;
      font-size: 15px !important;
      line-height: 20px !important;
      margin: 0 0 2px 0 !important;
    }

    .review-selection-filter-values {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: rgba(60, 60, 67, 0.6);
      margin: 0 !important;
    }
  }

  .share-actions {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 35px;

    & button {
      margin: auto;
    }
  }
}

.hcp-report-sharing-modal {
  .report-recipient-form-wrapper {
    padding-inline: 10px;

    .form-group {
      label {
        margin-bottom: 4px;
      }

      input {
        margin-bottom: 8px;
        padding: 8px;
        height: 44px;
      }
    }

    .email-contactnumber-disclaimer  {
      background-color: $hoverColor;
    }
  }

  .share-report-button {
    background-color: $hcpReportButton;
    border-color: $hcpReportButtonBorder;
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0) !important;
      transition-timing-function: cubic-bezier(0.64, 0.125, 0, 1.01);
    }
  }

  .modal-dialog {
    transform: translate(0, 125%) !important;
    vertical-align: bottom !important;
    transition-timing-function: cubic-bezier(0.64, 0.125, 0, 1.01);

    .modal-content {
      border-radius: 15px 15px 0 0 !important;

      .modal-header {
        background-color: $white !important;
        border-radius: 15px 15px 0 0 !important;
        color: #101828;

        .modal-title {
          text-align: left !important;
          font-size: 16px !important;
          font-weight: 400 !important;
        }

        .close {
          background-image: url('../../assets/images//ic_close_grey.png');
        }
      }
    }
  }

  .recipient-detail-modal-title {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
}

.pop-up-wrapper {
  position: absolute;
  z-index: 1;

  .backdrop {
    background: black;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    pointer-events: none;
  }

  .warning-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $warningModalColor;
    border-radius: 14px;
    width: 300px;
    z-index: 2;
  }

  .warning-text-wrapper {
    text-align: center;
    border-bottom: 1px solid rgba(60, 60, 67, 0.36);
    padding: 10px 10px 0 10px;

    .warning-heading {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
    }

    .warning-text {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .warning-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .warning-button {
      border: none;
      background-color: transparent;
      color: $warningButton;
      font-size: 17px;
      flex: 1;
      line-height: 22px;
      padding: 10px 0 10px 10px;
    }

    .filter-change {
      font-weight: 600;
      border-left: 1px solid rgba(60, 60, 67, 0.36);
      padding: 10px 10px 10px 0;
    }
  }
}

.mobile-dropdown-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .options-wrapper {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 170px);
    overflow: auto;
    .option-label {
      display: grid;
      grid-template-columns: 1fr 11fr;
      align-items: center;
      
      input {
        width: 20px;
        height: 20px;
        margin: 0;
      }

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: $subtitleColor;
      }
    }
  }

  .custom-dropdown-confirm-btn {
    margin: auto;
  }

    &__search-container {
      position: relative;
  
      input {
        padding: 6px 10px;
        border-radius: 30px;
      }
  
      span {
        position: absolute;
        top: 10px;
        right: 16px;
      }

      .icon-font-a-close:hover {
        cursor: pointer;
      }
      
      .icon-font-a-close::before {
        font-size: 12px;
      }
    }
}

span.chevron-gray-color {
  color: $begulingBlue;
  position: absolute;
  right: 15px;
  top: 38px;

  &::before {
    font-weight: bolder !important;
    font-size: 12px !important;
  }
}

.help-block-info {
  color: rgba(60, 60, 67, 0.6);
  font-size: 12px;
}

.mobile-search-input {
  background-image: url("../../assets/images/ic_close.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position-y: center;
  background-position-x: right;
}