.toast-ios {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 10px 20px;
  transition: opacity 0.3s ease-in;
  opacity: 1;
  z-index: 1;
  width: 100%;
  height: 72px;
  .toast-text {
    position: fixed;
    bottom: 8px;
    font-size: 16px;
  }
  &.close {
    animation-name: toast-close;
    animation-duration: 300ms;
    animation-timing-function: cubic-bezier(0.64, 0.125, 0, 1.01);
    animation-fill-mode: forwards;
    opacity: 0;
  }

  &.toast-success {
    background-color: rgba(39, 174, 96, 1);
  }

  &.toast-error {
    background-color: rgba(192, 57, 43, 1);
  }
}
