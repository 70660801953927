@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";
@import "./styles.scss";

.pain-overview-wrapper {
  max-width: 100%;
  height: 100%;
  margin-top: -83px;
  padding: 16px;
  padding-bottom: 24px;
  max-height: 104px;
}

.pain-score-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
  max-height: 20px;

  p {
    margin: 0;
  }
}

.pain-score-value-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: self-end;
  max-height: 40px;
}

.pain-score-emoji {
  max-width: 40px;
  margin-right: 16px;
}

.detailed-score-button {
  border: none;
  background-color: transparent;
}

.pain-overview-loader {
  height: 104px;
  align-items: center;
  padding: 0;
}
