@import "../../../assets/stylesheets/variables.scss";

.info-block-wrapper {
  display: flex;
  column-gap: 4px;
  .info-block-text {
    color: $congressBlue;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px; /* 157.143% */
  }
  .text-clickable {
    text-decoration: underline;
  }
}
