@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";

.title-font-regular {
    @include font-properties(16px, 600, 20px, $charcoalGray);
}

.title-font-small {
    @include font-properties(12px, 600, 20px, $black);
}

.text-font-small {
    @include font-properties(12px, 400, 14px, $black);
}

.highlighted-text-font {
    @include font-properties(24px, 600, 24px, transparent);
}

.chart-wrapper {
    position: relative;
    width: 100%;
}

.chart-title {
    margin-top: 16px;
}

.summary-page-divider {
    margin: 0 -15px 0 -15px;
    min-width: 100%;
    height: 4px;
    background-color: $silverGray;
}

.summary-page {
    &__heading-wrapper {
        margin-top: -70px;
    }

    &__user-info {
        text-align: center;
        border-bottom: 1px solid #EBECEF;
        margin-inline: -15px;
        padding: 6px 0;
        background-color: $mobileThemeColor;
        position: relative;

        &__back-icon {
            font-size: 24px !important;
            position: absolute;
            left: 15px;
            top: 20%;
            color: $white;
        }

        &__heading {
            color: $white;
            p {
                margin-bottom: 2px;
            }

            &__user-name {
                @include font-properties(18px, 300, 20px, $white);
            }

            &__procedure {
                @include font-properties(10px, 300, 12px, $white);
            }
        }
    }

    &__procedure-name {
        padding: 17px 0;
        @include font-properties(20px, 400, 20px, $charcoalGray);
        background-color: $slightGray;
    }

    &__procedure-date {
        @include font-properties(14px, 400, 20px, $steelBlueGray);
        background-color: $hoverColor;
        padding: 2px 0 0 15px;
        margin: 0 -15px 0 -15px;
    }
}

.indications-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 12px;
    padding-top: 16px;

    .indication {
        grid-column: span 1;

        &__title {
            margin-bottom: 4px;
        }
        &__value {
            &__label {
                text-transform: capitalize;
                margin-left: 6px;
            }

            &__image {
                max-width: 18px;
            }
        }
    }
}

.patient-goals-wrapper {
    .patient-goals-title {
        margin-top: 16px;
    }
}

.pain-chart-title, .stimulator-chart-title {
    margin: 16px 0;
}

.highest-pain-reduction {
    margin: 16px 0 16px 0;
}