.lightbox-modal {
  background: rgba(black, 0.2);

  .modal-content {
    background: transparent;
    box-shadow: none;
    border: none;
    text-align: center;

    .spinner-image {
      width: 70px;
      height: 70px;
    }
  }
}