@import "../../../assets/stylesheets/variables.scss";

.view-data-download-request-container {

  margin-top: -20px;
  width: 100%;

  .welcome-header {
    background: #EFF2FC;
    padding: 15px;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
  }

  .login-form-view {
    width: 60%;
    margin: auto;

    .timer {
      color: #7c7c7c;
    }

    .resend-code {
      color: #2B80BB;
    }
  }

  .input-container {
    margin-top: 15px;
    text-align: center;
  }

  input {
    width: 50%;
    margin: auto;
    text-align: center;
    font-size: 20px !important;
  }
  .input-label-without-value, .input-label-with-value{
    position: relative;
  }
  .input-label-without-value{
    top: 20px;
  }
  .input-label-with-value{
    top: 5px;
  }
  .text-info {
    font-size: 18px;
    font-weight: 300;
    color: #000;
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .text-info-small {
    font-size: 12px;
    font-weight: 300;
    color: #000;
    margin: 5px 5px 5px 5px;
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 12px;
    }

    .link {
      color: $primaryTextColor;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
    .not-active {
      color: darkgray !important;
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }

  .btn-primary {
    font-size: 16px;
    width: 130px;
    height: 30px;
    margin: 30px 10px 10px 10px;
  }
  .download {
    width: 200px !important;
  }

  .help-block {
    color: $red
  }

}

.request-data-status {
  display: block;
  text-align: center;
  margin-top: 50px;
  .data-container {
    padding-bottom: 5px;
    font-weight: 300;
    font-size: 14px;
    .lbl-name {
      display: inline-block;
    }
    .value {
      display: inline-block;
    }
    .expiring-text {
      font-size: 12px;
    }
  }
}
