@import "../../assets/stylesheets/variables.scss";

.flash-message {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	position: fixed;
	top: 0;
	width: 100%;
	min-height: 62px;
	// text-align: center;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0 0 20px -5px black;
	z-index: 10000;
	padding: 5px;

  @media screen and (max-width: 768px) {
  	min-height: 52px;
  }

  &.success {
  	background-color: rgba($successColor, 0.9);
  }

  &.error {
  	background-color: rgba($errorColor, 0.9);
  }

	.message-content {
		-webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
		align-self: auto;
		text-align: center;

		// margin: 0;
		line-height: 18px;
		font-weight: 300;
		@media screen and (max-width: 768px) {
	  	// line-height: 52px;
		}
		@media screen and (max-width: 572px) {
			font-size: 12px;
			// text-align: left;
		}

		.flash-message-icon {
			top: 2px;
			right: 10px;
			position: relative;

			@media screen and (max-width: 572px) {
				top: 5px;
			}

			&.success {
				color: green;
			}
			&.error {
				color: $textRed;
			}
		}
	}
	.close-flash-icon {
		-webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
		align-self: auto;

		// position: absolute;
    // right: 20px;
    // top: 15px;
    @media screen and (max-width: 768px) {
	  	// top: 10px;
	  }

    &.success {
			color: green;
		}
		&.error {
			color: $textRed;
		}
	}
}
