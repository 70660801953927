.hcp-outcomes-iframe {
  width: 98vw;
  height: 100vh;
}

.hcp-outcomes-button {
  max-width: fit-content;
  margin-left: auto;
  margin-right: 16px;
}

.hcp-dashboard-component-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}