@import "../../assets/stylesheets/variables.scss";
.conversation-modal {
    .navigate-button-container {
        min-height: 300px;
        width: 50%;
        margin: 0 auto;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;

        .button-separator {
          margin: 20px 0px;
          font-size: 16px;
          font-style: italic;
          font-weight: 100;
        }

        .button-explanation {
          margin-top: 10px;
          font-size: 12px;
        }
    }
}

.action-button-container {
  margin-top: 20px;

  .btn-default {
    margin-right: 20px;
  }
  .btn-primary[disabled],
  .btn-primary[disabled]:hover {
    background-color: $lightGray !important;
    border: 1px solid $lightGray !important;
  }
}

.assign-template-header {
  margin-bottom: 20px;
}
.system-template-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid $lightGray;
  padding: 10px 0px;

  .template-row {
    margin-bottom: 10px;
    cursor: pointer;

    span {
      &:nth-of-type(3) {
        font-size: 12px;
        color: grey;
        margin-top: 3px;
      }
    }

    .icon {
      &::before {
        font-size: 16px;
        color: $themeColor;
      }
    }

    .template-info.template-info-inline {
      display: inline;
      margin-left: 2%;
    }

    .template-info {
      display: block;
      width: 95%;
      margin-left: 5%;
      padding-bottom: 0px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
