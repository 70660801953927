@import "assets/stylesheets/variables.scss";

.pats-order-form-wrapper {
  height: 100vh;
  display: grid;
  margin-top: -70px;
  grid-template-rows: 56px 52px calc(100vh - 108px);
}

.pats-order-form-wrapper-no-header {
  height: 100vh;
  margin-top: -70px;
  .pats-order-body {
    height: 100%;
  }
}

.pats-order-body-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 16px;
}

.error-boundary-mobile-fallback-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.5;
  margin-top: -70px;

  &__error-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

.pats-team-contact-text__number {
  color: $congressBlue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
  text-decoration-line: underline;
}

.lost-and-damaged-parts-wrapper {
  margin: 0 12px;
}

.order-review-wrapper {
  margin: 0 12px;
}
