@import "../../../assets/stylesheets/variables.scss";
.completed-procedure-container {
  min-height: calc(100vh);
  padding-bottom: 10px;
}

.completed-procedure-invite-code-container {
 border: 1px solid $gray;
  .text {
    background: $infoColor;
    font-weight: 300;
    padding: 20px 30px;
    margin-bottom: 10px;
  }
  .invite-code-block {
    padding: 10px 30px 20px 30px;
  }
}
.completed-procedure-welcome-header {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 300;
  @media screen and (max-width: 572px) {
    font-size: 24px;
  }
}
