@import "assets/stylesheets/variables.scss";

.order-detail-modal {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .patient-name {
    font-size: 40px;
    font-weight: 600;
    line-height: 44px;
  }
  .order-details {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    .details-outer-wrapper {
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }
      .order-details-title {
        display: grid;
        grid-template-columns: 0.3fr 0.6fr;
        flex: 1;
        justify-content: space-between;
        margin-top: 16px;
      }
      .shipping-info-title {
        margin-bottom: 16px;
      }

      .details-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        .details-half {
          display: grid;
          grid-template-columns: 0.5fr 0.5fr;
          justify-content: center;
        }
        .details-full {
          display: grid;
          grid-template-columns: 0.3fr 0.5fr 0.2fr;
          justify-content: center;
        }
        .key-value {
          display: flex;
          flex-direction: column;
          .key {
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
          }
          .value {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            justify-self: flex-end;
            padding-right: 17px;
          }
        }
        .order-submitted-data {
          margin-right: 32px;
          display: flex;
          .key {
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
          }
          .value {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            justify-self: flex-end;
          }
        }
        .last {
          justify-self: flex-end;
        }
      }
    }
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
  .complete-btn {
    width: 172px;
    height: 40px;
  }
}

.confirm-text-title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
}
.confirm-text {
  color: #121212;
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  line-height: 24px; /* 141.176% */
}
.confirm-btn {
  width: 215px;
}
