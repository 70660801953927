
@import "../../../assets/stylesheets/variables.scss";

.section-container{
  padding: 10px 10px 10px 20px;

  .lbl-currently-viewing{
    font-weight: 300;
    line-height: 2;
    text-align: right;
  }

  .manage-my-surgery-container{
    color:$primaryTextColor;
    .lbl-manage-my-surgery {
      padding-left: 5px;
      text-align: center;
      position: relative;
      top: -4px;
    }
  }

  .select-procedure-dropdown {
    .custom-select {
      padding: 5px !important;
      border: 1px solid $borderColor;
      border-radius: 2px;
    }
    padding-left: 10px;
    @media screen and (max-width: 736px) {
      padding: 0px;

      select {
        font-size: 12px;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $primaryTextColor;
        border-radius: 0;

        &.form-control {
          background-image: url('../../../assets/images/dropdown_icon.png');
        }
      }
    }
  }
}
