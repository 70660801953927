@import "../../assets/stylesheets/variables.scss";

.conversation-list-row {
  .conversation {
    display: flex;
  }
  .conversation-title {
    font-weight: 700;
  }
  .date {
    color: $lightGray;
    font-weight: 300;
  }
  .sent-on {
    font-weight: 100;
  }
  .dot:before {
    content: " \2022 ";
    color: $lightGray;
    margin-left: 2px;
    margin-right: 5px;
  }
  .margin-top-5 {
    margin-top: 5px;
  }
  .font-weight-300 {
    font-weight: 300;
  }
  .font-size-sm {
    font-size: 12px;
  }
  .app-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
