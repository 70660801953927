@import "../../../assets/stylesheets/variables.scss";
.add-procedure-header {
  font-size: 34px;
  font-weight: 100;
  margin: 30px 0;
  @media screen and (max-width: 572px) {
    margin: 15px 0;
    font-size: 24px;
  }
}
.add-procedure-container-modal {
  .add-procedure-data-modal {
    // margin: 0 10px !important;
    .procedure-select {
      // margin: 10px 0;
    }
    .provider-select {
      // margin: 10px 0;
    }
    .location-select {
      // margin: 10px 0;
    }
    .procedure-date{
      // margin-top: 20px;
    }
    .procedure-date-note {
      background: #FFF6EA;
      margin-top: 10px;
      padding: 10px;
      font-size: 12px;
      font-weight: 300;
    }
    .procedure-time {
      @media screen and (max-width: 572px) {
        // margin-top: 10px;
      }
    }
    .add-procedure-button {
      margin-top: 10px;
    }
  }
}

.add-procedure-container {
  margin: 0 10px !important;
  border: solid 1px $gray;
  overflow-y: auto;
  .add-procedure-data {
    margin: 20px 20px;
    .procedure-select {
      margin: 10px 0;
    }
    .provider-select {
      margin: 10px 0;
    }
    .location-select {
      margin: 10px 0;
    }
    .procedure-date{
      margin-top: 20px;
    }
    .procedure-date-note {
      background: #FFEEDA;
      margin-top: 10px;
      padding: 10px;
    }
    .procedure-time {
      @media screen and (max-width: 572px) {
        margin-top: 10px;
      }
    }
  }
}
