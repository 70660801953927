@import "../../assets/stylesheets/variables";


.bottom-margin-12 {
  margin-bottom: 12px;
}

.outcomes-sharing-modal {
  .report-recipients-wrapper {
    
    .help-block-info {
      color: rgba(60, 60, 67, 0.6);
      font-size: 12px;
      margin: 0;
    }

    .report-recipients {
      margin-top: 16px;

      input {
        border-color: $lightThemeColor;
      }
    }

    .form-control {
      height: 34px;
    }

    .icon-font-a-close::before {
      font-size: 12px;
    }

    .report-date-picker {
      .date-field .form-control[readonly] {
        height: 34px;
        border: 1px solid $lightThemeColor !important;
        border-radius: 4px;
        padding-inline: 5px;
      }
    }
    .messages-textbox textarea {
      border-color: $lightThemeColor;
    }
  }
}

.background-style {
  background-color: aliceblue;
  border-radius: 8px;
  padding: 8px 8px 0;
  margin: 12px -8px;
}

.full-screen-mobile {
  min-width: 100vw;
  min-height: 100vh;
}

.pop-up-wrapper {
  position: absolute !important;
  z-index: 10000 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}