@import "assets/stylesheets/variables.scss";

.dashboard-wrapper {
  display: flex;
  .data-show-wrapper {
    width: 100%;
  }
  .no-order-text {
    color: $disabledGrey;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-align: center;
  }
}
