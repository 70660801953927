// Grid variables
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
// Colors
$lightGray: #c9c9c9;
$faintGrey: #f7f2f2;
$lightNavyBlue: #0c0ca5;
$gray: #9b9b9b;
$red: #f4787d;
$beige: #fbf6ec;
$darkBeige: #dbceb6;
$darkBrown: #8b6825;
$purple: #9c90ec;
$white: #ffffff;
$black: #000000;
$lightWhite: #f5f5fa;
$infoBackground: #efefef;
$createTaskInfoBackground: #fff6ea;
$themeColor: #2072b9;
$lightThemeColor: #00b2d0;
$violetColor: #8973eb;
$lightTabColor: rgba(0, 178, 208, 0.1);
$btnPrimaryColor: $lightThemeColor;
$borderGreyColor: #cecece;
$primaryTextColor: $lightThemeColor;
$disabledColor: $lightGray;
$textRed: $red;
$successColor: #bde5bc;
$errorColor: #fcdae4;
$appGreen: #71d1aa;
$appOrange: #fda429;
$facetBackground: #f4feff;
$lightBlueGreen: #f4fcfe;
$darkGreen: #4eb189;
$badgeRed: $red;
$hoverTextColor: #286090;
$lightBlue: #e4eff6;
$hoverColor: #e6edf3;
$defaultBtnHoverColor: #0093ac;
$darkGray: #4a4a4a;
$orangeColor: #e68000;
$blueBorderColor: #4a90e2;
$preLoginImageBackground: #caeaf6;
$activeColor: #bcd0e2;
$mrnCodeBorderColor: #b5d4dc;
$mrnCodeBackgroundColor: #edfbff;
$mrnCodeTextColor: #44727f;
$masqueradingHeaderColor: #f7debd;
$borderColor: #e0e0e0;
$inputFocusedColor: #007aff;
$procedureNameOnCardColor: #2072b9;
$providerLabelColor: #8a672c;
$lightPurple: #d0c8fc;
$emptyImageTextColor: #909895;
$bodyBackgroundColor: #f5f5fa;
$infoColor: #fff6ea;
$disabledFieldBackgroundColor: #eeeeee;
$disabledFieldTextColor: #555555;
$tooltipColor: #007b92;
$painReductionColor100: #a0d5c1;
$painReductionColor90: #a9c7a9;
$painReductionColor80: #b9c590;
$painReductionColor70: #e9cf83;
$painReductionColor60: #eec368;
$painReductionColor50: #e5b466;
$painReductionColor40: #fc9c39;
$painReductionColor30: #f59746;
$painReductionColor20: #ea8c68;
$painReductionColor10: #f6806d;
$painReductionColor00: #f37980;
$darkBlue: #081d43;
$lightGrey: #292929;
$slightGray: #fafafa;
$hcpReportGray: #afbaca;
$begulingBlue: #5e718d;
$hcpReportButton: #1010eb;
$hcpReportButtonBorder: #dde7ff;
$hcpReportSecondaryButton: #f3faff;
$warningModalColor: #f2f2f2;
$warningButton: #007aff;
$reportLabelColor: #3d4a5c;
$subtitleColor: #2d3643;
$deepBlue: #105693;
$congressBlue: #004b87;
$sapphire: #001e46;

// colors for mobile webcontainer
$mobileThemeColor: #0073bf;
$mobileThemeHeaderBarColor: #5184bd;
$mobileThemeLightColor: #06b2d1;
$mobilePrimaryDark: #140f4b;
$mobileDisabledGrey: #999;
$mobileDangerColor: #f4787d;
$mobileSecondaryColor: $begulingBlue;
$mobileSecondaryDark: $hcpReportButton;
$placeholderColor: #959594;
$imageBorderGrey: #d9d9d9;

:export {
  lightBoxModalBackgroundColor: rgba($black, 0.2);
}
$dataTableHeader: #f9fafb;
$dataTableHighlight: rgba(252, 244, 172, 0.4);
$placeholderGrey: #4a4a4c;
$inputBoxBordeColor: #d7dfe9;
$managerAppThemeColor: #140f4b;
$alertNegativeDark: #a3001e;
$mobileThemeColor: #0073bf;
$mobileThemeExtraLightColor: #4fafcd;
$disabledGrey: #979698;
$tableHeaderBackground: #e9eff6;
$charcoalGray: #333942;
$steelBlueGray: #606a7b;
$silverGray: #ececec;
$jungleGreen: #44af69;
