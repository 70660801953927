@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_100.otf") format("otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_300.otf") format("otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_500.otf") format("otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_700.otf") format("otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_900.otf") format("otf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_100_Italic.otf") format("otf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_300_Italic.otf") format("otf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_500_Italic.otf") format("otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_700_Italic.otf") format("otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-ot/MuseoSans_900_Italic.otf") format("otf");
  font-weight: 900;
  font-style: italic;
}

/* then for WOFF-capable browsers */
@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_100Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_300Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_500Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_700Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_900Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_100_ItalicWeb.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_300_ItalicWeb.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_500_ItalicWeb.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_700_ItalicWeb.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'MuseoSans';
  src: local("MuseoSans"), url("../fonts/museo-sans-family-web/MuseoSans_900_ItalicWeb.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face{
  font-family:"Avenir Next World";
  src:url("../fonts/avenir-next-world-family-ttf/bae40acb-d6f4-4286-8b5c-a268303f2bcf.woff2") format("woff2"),url("../fonts/avenir-next-world-family-ttf/de44dcbe-a981-426e-b310-c56554485383.woff") format("woff");
  font-weight: 250;
  font-style: normal;
}

@font-face{
  font-family:"Avenir Next World";
  src:url("../fonts/avenir-next-world-family-ttf/09ab0626-bb45-4650-acc8-0182d693df02.woff2") format("woff2"),url("../fonts/avenir-next-world-family-ttf/1397e812-9307-4267-8a99-8a22ea7c69c7.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family:"Avenir Next World";
  src:url("../fonts/avenir-next-world-family-ttf/0c8a5d21-8a14-4451-8145-695071809cb7.woff2") format("woff2"),url("../fonts/avenir-next-world-family-ttf/a9c2f4a1-e39a-4cf6-89f2-a7b56fd6ad18.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family:"Avenir Next World";
  src:url("../fonts/avenir-next-world-family-ttf/074dfc70-6436-4f4b-9314-d1e69287aea1.woff2") format("woff2"),url("../fonts/avenir-next-world-family-ttf/0c4cefca-3b78-44b9-8b2b-667bbabffe38.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Ultralight.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face{
  font-family:"SF Pro Text";
  src: local("SF-Pro-Text"), local("SF Pro Text"), local("SFProText"), url("../fonts/sf-pro-text-otf/SF-Pro-Text-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
.font-museo {
  font-family: 'MuseoSans';
}
