@import "../../../assets/stylesheets/variables.scss";
.manage-procedures-container {
  background: #F6F5FB;
  margin-top: -18px;
  .manage-procedure-header-container {
    margin-bottom: 10px;
    .back-icon {
      // position: absolute;
      // left: 70px;
      // top: 25px;
      margin-top: 20px;
      margin-left: -15px;
      text-align: center;
      font-size: 26px;
      @media screen and (max-width: 572px) {
        font-size: 18px;
        margin-left: -5px;
      }
      @media screen and (min-width: 573px) and (max-width: 1024px) {
        margin-left: 0px;
      }
    }
    .manage-procedure-header {
      @media screen and (max-width: 572px) {
        font-size: 24px;
        margin-top: 0px;
      }
      .manage-procedures-title {
        font-size: 34px;
        font-weight: 100;
        margin-top: 20px;
        text-align: center;
        z-index: 1;
        @media screen and (max-width: 572px) {
          font-size: 18px;
          margin-top: 25px;
        }
      }
      .manage-procedures-add-procedure {
        margin-right: -9px;
        @media screen and (max-width: 572px) {
          //font-size: 24px;
          padding-top: 8px;
          margin-right: 10px;
        }
        @media screen and (min-width: 573px) and (max-width: 1024px) {
          margin-right: -5px;
        }
        .btn {
          padding: 0;
          font-size: 14px;
          margin-top: 30px;
          margin-right: -13px;
          float: right;
          color: $lightThemeColor;
          padding: 0;
          margin-bottom: 10px;
          width: 150px; // @media screen and (max-width: 572px) {
          //   width: 50px;
          // }
          // @media screen and (max-width: 768px) {
          //   //width: 110px;
          // }
          .manage-procedures-add-procedure-label {
            @media screen and (max-width: 768px) {
              font-size: 11px;
            }
          }
          .icon:before {
            @media screen and (max-width: 768px) {
              font-size: 13px;
              top: 3px;
              left: -2px;
            }
          }
        }
        .procedure-action-buttons {
          margin-right: 0;
          color: $lightThemeColor;
          &:hover {
            color: #286090;
          }
          &:before {
            font-size: 16px;
            top: 2px;
            left: -2px;
          }
        }
      }
      .fab-add-procedure {
        z-index: 1;
        width: 40px;
        height: 40px;
        background-color: $btnPrimaryColor;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
        font-size: 30px;
        font-weight: 300;
        color: white;
        text-align: center;
        line-height: 38px;
        position: fixed;
        right: 25px;
        bottom: 40px;
      }
      .fab-add-procedure:hover {
        transform: scale(1.05);
      }
    }
  }
  .manage-procedures-data {
    width: 80%;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 572px) {
      width: 100%;
      margin: 0 auto;
    }
  }
  .manage-procedures-item-container {
    //margin-top: 10px;
    height: calc(100vh);
    background: white;
  }
  .manage-procedures-item {
    border-bottom: 1px solid #c9c9c9;
    background: white;
    margin: 0;
    padding: 15px 20px;
    &:last-child {
      //border-bottom: 0;
    }
    .procedure-date {
      color: $gray;
      font-weight: 100;
    }
    .is-active-procedure {
      color: $appGreen;
      padding-top: 15px;
    }
    .procedure-name {
      font-size: 16px;
      padding-bottom: 15px;
      font-weight: 300;
    }
    .switch-procedure-btn {
      padding: 0 10px;
      display: inline-block;
    }
    .procedure-delete {
      display: inline-block;
      color: $red;
      padding: 0 10px;
      .procedure-delete-label {
        font-size: 14px;
        margin-top: 5px;
      }
    }
    .icon:before {
      font-size: 20px;
      left: -5px;
      top: 4px;
    }
  }
}

.custom-select {
  padding: 5px 5px 5px 0px !important;
  border-bottom: 1px solid $gray;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right;
  font-size: 14px;
  font-weight: 300;
  background-position-x: 98%;
  background-image: url('../../../assets/images/dropdown_icon.png');
}

.custom-select-options {
  border: 1px solid $borderColor;
  padding: 5px 10px !important;
  border-radius: 4px;
  position: absolute !important;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 2px 7px $lightGray;
  top: 30px;
  .single-option {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 5px;
    padding-bottom: 5px;
    position: relative;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .proc-name {
      font-size: 14px;
      font-weight: 500;
    }
    .proc-date {
      font-size: 12px;
      font-weight: 300;
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 10px;
      &:before {
        font-size: 12px;
        color: $themeColor;
      }
    }
  }
}

.action-container {
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.invite-code-footer {
  font-size: 12px;
  font-weight: 300;
}
