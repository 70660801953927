@import "assets/stylesheets/variables.scss";

.collapsing-sidbar-wrapper {
  height: 100%;
  .bar {
    height: 100%;
    background-color: $themeColor;
    transition: width 0.5s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .bar-open {
    width: 200px;
  }
  .bar-close {
    width: 96px;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    padding: 48px 28px 48px 28px;
    flex: 0.499;
    .item {
      color: $white;
      display: flex;
      justify-content: center;
      column-gap: 4px;
      padding: 8px 0px;
      width: 100%;
      height: 42px;
      transition: width 0.5s;
      .text {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
        overflow: hidden;
        text-align: center;
      }
      .icon {
      }
    }
    .active {
      background-color: $white;
      color: $black;
      width: fit-content;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      border-radius: 42px;
    }
    .expanded {
      width: 100%;
      padding: 8px 16px;
    }
  }
  .back-button {
    color: $white;
    flex: 0.002;
    align-self: center;
    justify-self: center;
  }
  .logout-icon {
    color: $white;
    align-self: center;
    justify-self: center;
    padding-bottom: 40px;
  }

  .logout-section {
    color: $white;
    display: flex;
    column-gap: 4px;
    padding: 8px 16px;
    .logout-icon {
    }
    .logout-text {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
