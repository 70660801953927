@import "assets/stylesheets/variables.scss";

.field-value {
  color: $black;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.078px;
}
.bold {
  font-weight: 400;
}
