@import "../../../assets/stylesheets/variables.scss";
.patient-navigation-container {
  margin: 0;
  padding: 0;
  background: $themeColor;
  ul {
    padding: 0 20px;
    background: $themeColor;
    li {
      padding: 5px 0;
      .icon:before {
        font-size: 18px;
        left: -5px;
        top: 2px;
      }
      .tab-label {
        display: inline-block;
        position: relative;
        font-size: 14px;
        font-weight: 100;
        top: -1px;
        @media screen and (max-width: 767px) {
          display: block;
          top: 4px;
        }
      }
      a {
        color: white;
        padding: 5px;
        font-size: 14px;
        font-weight: 300;
        &:hover,
        &:active,
        &:focus {
          background: inherit;
          color: white;
        }
      }
      &.active {
        a {
          padding: 5px;
          border-radius: 40px;
          background: white;
          color: $themeColor;
          outline: none;
          &:hover,
          &:active,
          &:focus {
            background: white;
            color: $themeColor;
          }
        }
        .tab-label {
          display: inline-block;
          color: #000;
          position: relative;
          font-size: 14px;
          font-weight: 300;
          top: -1px;
          @media screen and (max-width: 572px) {
            display: block;
          }
        }
      }
    }
  }
  .tab-drop-down {
    .icon-font-a-more:before {
      top: 10px;
    }
    .more-label {
      display: block;
      font-size: 12px;
      font-weight: 100;
      position: relative;
      top: 4px;
    }
    .caret {
      display: none;
    }
    &.open {
      .icon-font-a-more:before {
        top: 6px;
      }
      .more-label {
        top: 2px;
      }
      .dropdown-toggle {
        padding: 6px 0;
        background-color: #19578E;
        border-color: none;
        font-size: 16px;
      }
      .dropdown-toggle:focus,
      .dropdown-toggle:hover,
      .dropdown-toggle:active {
        padding: 4px 0;
        background-color: #19578E;
        border-color: none;
        font-size: 16px;
      }
    }
    ul {
      margin-top: 0px;
      border-radius: 0px;
      right: 10px;
      li {
        width: 100%;
        border-bottom: 1px solid $lightGray;
        font-size: 12px;
        &:last-child {
          border: none;
        }
        a,
        a:focus,
        a:hover,
        a:active {
          background-color: inherit;
          padding-bottom: 10px;
        }
        .tab-label {
          position: relative;
          top: 3px;
        }
        &.active {
          .tab-label {
            top: 0px;
            color: #fff;
          }
        }
      }
    }
  }
  .nav-pills>li+li {
    margin: 0;
  }
  @media screen and (max-width: 767px) {
    ul {
      padding: 0;
      li {
        padding: 0px;
        width: 25%;
        float: left;
        margin: 0;
        text-align: center;
        .icon:before {
          left: 0px;
        }
        a {
          .tab-label {
            font-size: 12px;
            font-weight: 100;
          }
          .icon:before {
            top: 8px;
          }
        }
        &.active {
          a {
            margin-bottom: 0px;
            border-radius: 0;
            background: #19578E;
            .icon:before {
              color: #fff;
              top: 8px;
            }
            &:hover,
            &:active,
            &:focus {
              background: #19578E;
              color: #fff;
              outline: none;
            }
          }
          .tab-label {
            display: block;
            color: #fff;
            font-size: 12px;
            font-weight: 100;
            padding-top: 5px;
          }
        }
      }
    }
  }
  .custom-more-nav {
    &.active {
      a[aria-expanded="false"] {
        padding-bottom: 10px !important
      }
    }
    &.open {
      a {
        margin: 0 !important;
        padding-bottom: 11px !important;
        border-radius: 0 !important;
        .icon-font-a-more:before {
          top: 11px;
        }
        .more-label {
          display: block;
          font-size: 12px;
          font-weight: 100;
          position: relative;
          top: 5px;
        }
      }
    }
  }
}
