@import "../../../assets/stylesheets/variables.scss";

.procedure-card{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  border-bottom: 1px solid $disabledColor;
  margin-left: 0px !important;
  margin-right: 0px !important;

  @media screen and (max-width: 768px) {
    &:first-child {
      border-top: 1px solid $disabledColor;
    }
  }

  .icon-container{
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    position: relative;
    text-align: center;

    img {
      width: 70px;

      @media screen and (max-width: 768px) {
        width: 50px;
      }
    }
  }

  .content-container{
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;

    border-left: 1px solid $borderColor;
    margin: 20px 0;

    @media screen and (max-width: 768px) {
      margin: 10px 0;
    }

    .procedure-name{
      font-size: 16px;
      margin:5px 0;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 500;
      }
    }

    .procedure-date{
      margin-left: 0 !important;
      margin-right: 0 !important;
      color:$disabledColor;

      @media screen and (max-width: 768px) {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }

  .tick-container{
    padding-right:30px;
  }
}

div.procedure-card:hover{
  background-color: $lightWhite;
}
