@import "../../../../assets/stylesheets/variables.scss";
.question-search-container {
  margin-top: -17px;
  min-height: calc(100vh);
  background: #F6F5FB;

  .search-main-container {
    background: white;
    min-height: calc(100vh);

    .search-input {
      position: relative;
      margin-top: 15px;
      border: 1px solid $lightGray;
      border-radius: 5px;
      padding-left: 5px;
      input {
        background-image: url('../../../../assets/images/ic_search.png');
        background-repeat: no-repeat;
        border: none;
        background-size: 15px;
        padding-left: 20px;
        padding-top: 1px;
        background-position: left;
      }

      .clear-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 5px;
        top: 5px;
        background: #E0E0E0;
        border-radius: 50%;
        &.icon:before {
          text-align: center;
          font-size: 10px;
          color: $gray;
          float: right;
          margin-top: 1px;
          margin-right: 5px;
        }
      }
    }

    .search-results{
      margin-top: 20px;
      padding-bottom: 30px;
      max-height: calc(100vh);
      overflow-y: auto;
      .lbl-search-results {
        padding: 5px 5px;
        font-size: 14px;
        background: #F6F5FB;
        @media screen and (max-width: 572px) {
          font-size: 12px;
        }
      }
      .loading-indicator {
        margin-top: -30px;
      }
      .end-of-search {
        font-size: 16px;
        font-weight: 300;
        margin: 20px 0;
        text-align: center;
      }
      .no-results {
        margin-left: 10px;
        font-weight: 300;
        .header{
          margin: 10px 0;
        }
        .subtext{
          margin: 10px 0;
        }
        .create-question {
          margin-top: 15px;
          padding: 10px 0;
          background: #f6f5fb;
          .create-question-btn {
            border: 1px solid #3DAEC1 !important;
            color: $primaryTextColor;
            border-radius: 50px;
            padding: 3px 7px;
            margin-right: 10px;
            .btn-text {
              margin-top: -10px;
              padding-right: 5px;
              padding-left: 5px;
            }
            .icon:before {
              font-size: 16px;
              font-weight: 700;
              top: 3px;
              left: 3px;
            }
          }
        }
      }
    }
  }
}
