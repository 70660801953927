@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";

.heading-font {
  @include font-properties(18px, 400, 26px, $congressBlue);
}

.edit-icon {
  display: flex;
  column-gap: 4px;
  text-decoration: underline;
  color: $mobileThemeLightColor;
  justify-content: flex-end;
  align-items: center;
  width: 80px;
}
.required:before {
  content: " *";
  color: $mobileDangerColor;
  display: inline;
}

.title-font {
  @include font-properties(24px, 600, 28px, $black);
}

.content-font-1 {
  @include font-properties(18px, 300, 26px, $black);
}
.success-text {
  text-align: left;
}

.input-border {
  height: 46px;
  border: 1px solid #959594;
  border-radius: 6px;
}

.flex-row {
  @include display-flex();
  @include flex-direction(row);

  .flex-child {
    flex: 1;
  }
}

.flex-justify-content {
  @include display-flex();
  @include justify-content(space-between);
}

.gap-16 {
  column-gap: 16px;
}

.pats-btn-primary {
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.25),
    0px -4px 8px 0px rgba(102, 102, 102, 0.25);
  @include font-properties(18px, 400, normal, $white);
  background-color: $mobileThemeLightColor !important;
  border: 1px solid $mobileThemeLightColor !important;
  border-radius: 61px;
  padding: 14px 0 12px;
  text-align: center;
  &:disabled {
    background-color: $mobileDisabledGrey !important;
    cursor: $white;
    border: none !important;
    box-shadow: none;
  }
}

.pats-btn-secondary {
  width: 100%;
  box-shadow: 0px 4px 8px 0px rgba(102, 102, 102, 0.25),
    0px -4px 8px 0px rgba(102, 102, 102, 0.25);
  @include font-properties(18px, 400, normal, #4fafcd);
  background-color: $white !important;
  border: 1px solid #4fafcd !important;
  border-radius: 61px;
  padding: 14px 0 12px;
  text-align: center;
}

.input-label {
  color: $congressBlue;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.device-number-dob-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  .device-number-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

// styles for device type view
.device-type-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  .device-details {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    .device-info {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
  }
  .provider-name-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .provider-name-fields {
      display: flex;
      column-gap: 16px;
    }
  }
}

// styles for device-parts view
.device-part-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  .device-part-info {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .device-part-tiles {
    display: flex;
    width: 100%;
    column-gap: 16px;
    row-gap: 16px;
    flex: 1;
    flex-wrap: wrap;

    .device-part-tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 8px;
      align-items: center;
      width: calc(50% - 8px);
      .image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 1px solid $imageBorderGrey;
      }
      .selected {
        border-radius: 4px;
        border: 2px solid $mobileThemeLightColor;
        background: #fafafa;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
      .selected-display-name {
        color: $mobileThemeLightColor;
      }
      .display-name {
        color: $black;
      }
    }
  }
  .no-part-selected-error {
    color: $mobileDangerColor;
  }
}

// style for lost and damage parts view
.lost-and-damaged-parts-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

// style for edit shipping details
.edit-shipping-details-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin: 0 12px;
  .field-value {
    color: $black;
    font-size: 18px;
    font-weight: 300;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.078px;
  }
  .bold {
    font-weight: 400;
  }

  .address-field-2-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .city-state-wrapper {
    .city-container {
      width: 50%;
    }
    .state-container {
      width: 50%;
    }
  }
  .country-zip-wrapper {
    display: flex;
    row-gap: 8px;
    flex-direction: column;
    .country-zip {
      display: flex;
      column-gap: 16px;
      .zip-container {
        width: 46%;
      }
      .country-container {
        width: 50%;
      }
    }
  }
}

// view for submit order screen
.submit-order-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  .submit-order-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    margin-top: -16px;
    @media screen and (max-height: 667px) {
      justify-content: flex-end;
    }
    justify-content: center;
    .order-placed-msg {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      align-items: center;
      justify-content: center;
    }
    .delivery-image-wrapper {
      width: 88px;
      height: 88px;

      img {
        max-width: 100%;
      }
    }
  }

  .submit-order-button {
    flex: 0.2;
    align-content: center;
  }
}

.edit-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
}
