@import "../../assets/stylesheets/variables.scss";

.input-container {
  text-align: left;
}
.welcome-section {
  h3 {
    color: #071E43;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .mms-welcome-logo {
    margin-top: 0;
  }
}
.content-text {
  color: $black;
  font-size: 16px;
  font-weight: 100;
}
.mms-welcome-logo {
  height: 60px;
  margin-top: 10px;
}
.button-container {
  margin-top: 20px;
}
.patient-register-section {
  color: $black;
  font-size: 16px;
  font-weight: 100;

  h2 {
    font-weight: 700;
  }
}
.larger-button {
  min-width: 180px;
}
.register-container button {
  min-width: 180px;
}

.contact-info {
  font-weight: bold;
  font-size: 12px;
  text-decoration: underline;
}

.register-container {

  .contents-container {
    position: relative;
    top: -60px;

    h2 {
      color: #0A2045;
      font-weight: 700;
      margin-bottom: 2px;
    }
  }

  .welcome-view-container {
    h2 {
      color: #1C4C83;
    }
  }

  .image-container {
    position: relative;
    top: -70px;
    margin-left: -15px;
    margin-right: -15px;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      width: 400px;
      max-height: 33vh;
      height: auto;
      outline: 5px solid white;
      outline-offset: -15px;
    }
  }

  button.btn-primary {
    background-color: #A1268A;
    border: none;

    &:hover, &:active, &:focus {
      background-color: #92227d !important;
      border: none;
    }
  }

  .back-btn {
    background: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border: none;

    &:hover, &:active, &:focus {
      background-color: white !important;
      color: $lightThemeColor !important;
      border: none !important;
    }
  }

  .download-btn {
    margin-top: 2rem;
  }

  .phone-input-container input, input {
    border-color: #A1268A;
    &:focus {
      border-color: #A1268A;
    }
  }

  .phone-input-container .input-label-with-value, .input-label-with-value {
    color: #A1268A !important;
  }

  .download-container {
    img {
      height: 80px;
      margin: 0 15px;
    }
  }

  // Till iPad Pro portrait specific styles
  @media screen and (max-width: 415px) {
    .welcome-view-container {
      text-align: left;
    }

  }

  // Bigger devices specific styles
  @media screen and (min-width: 416px) {
    .welcome-view-container {
      text-align: center;
    }

    .contents-container {
      width: 60%;
      margin-left: auto;
      margin-right: auto;

      .content-text, .content, .button-container {
        margin-top: 2rem;
      }
    }

    .phone-container {
      margin-top: 10px;
    }
  }
}
