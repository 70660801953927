@import "./variables.scss";

.smartbanner-show {
  margin-top: 0;
  margin-bottom: 80px;
}

.smartbanner {
  position: fixed !important;
  bottom: 0 !important;
  top: auto !important;
}

.smartbanner-ios {
  background: #F2F1F1 !important;
  border-bottom: none !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5) !important;

  .smartbanner-container {
    .smartbanner-close {
      border: none;
      box-shadow: none;
      text-shadow: none;
      color: black;
      font-family: 'MuseoSans', sans-serif;
      background: none;
      font-size: 16px;
    }
    .smartbanner-info, .smartbanner-info .smartbanner-title {
      color: black;
      text-shadow: none;
      font-family: 'MuseoSans', sans-serif;
      font-weight: 300;
    }
    .smartbanner-info  .smartbanner-title {
      font-weight: 500;
    }
    .smartbanner-button {
      background: $lightThemeColor;
      color: white;
      border: none;
      text-shadow: none;
      box-shadow: none;
    }
  }
}

// #smartbanner.android {
//   .smartbanner-container {
//     .smartbanner-close {
//       border: none;
//       background: none;
//       color: white;
//       box-shadow: none;
//       text-shadow: none;
//       top: 30px !important;
//     }
//     .smartbanner-info, .smartbanner-info strong {
//       color: white;
//       text-shadow: none;
//       font-family: 'MuseoSans', sans-serif;
//       font-weight: 300;
//     }
//     .smartbanner-info strong {
//       font-weight: 500;
//     }
//     .smartbanner-button {
//       border: none;

//       span {
//         background: white;
//         color: $themeLightColor;
//         border: none;
//         text-shadow: none;
//       }
//     }
//   }
// }