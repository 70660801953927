@import "../../assets/stylesheets/variables.scss";

.faq-section {
    margin-bottom: 20px;
    border-left: 2px solid #00B2D0;
    padding-left: 10px;

    .faq-answer {
        margin-top: 5px;
        padding: 0;
        font-family: 'MuseoSans', sans-serif;
        font-size: 14px;
        font-weight: 300;
        white-space: pre-wrap;
        word-break: break-word;
    }

    .faq-tag {
        margin-top: 10px;
        font-size: 12px;
    }
}
