@import "../../../assets/stylesheets/variables.scss";

.text-area-field-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  .character-limit-text {
    display: flex;
    justify-content: space-between;
    color: $placeholderColor;
    font-size: 11px;
    font-weight: 400;
    line-height: 11px; /* 100% */
    letter-spacing: -0.2px;
  }
}

textarea {
  resize: none;
  height: 100px !important;
}

textarea::placeholder {
  font-weight: 500;
  font-size: 18px;
  color: $placeholderColor;
}
