@import "../../../assets/stylesheets/variables.scss";

.input-label {
  display: inline;
}
.required:before {
  content: " *";
  color: $mobileDangerColor;
  display: inline;
}

.text-field-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
