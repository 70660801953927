@import "../../../assets/stylesheets/variables.scss";
.passive-mode-info-containter {
  margin-top: -19px;
  text-align: center;
  .welcome-header {
    background: #EFF2FC;
    padding: 15px;
    font-weight: 100;
    font-size: 16px;
  }
  .text-info {
    font-size: 14px;
    font-weight: 300;
    width: 40%;
    padding: 20px;
    margin: 0 auto;
    color: #000;
    @media screen and (max-width: 575px) {
      font-size: 12px;
      width: 80%;
    }
    @media screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 12px;
      width: 80%;
    }
  }
  .padding-20 {
    padding: 20px;
  }
}
