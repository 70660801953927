.share-report-mobile-fallback-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0.5;
    margin-top: -70px;
    
    &__error-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
    }
}