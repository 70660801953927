@import "assets/stylesheets/variables.scss";

.order-search-form {
  height: 100%;
  .search-bar-form-wrapper {
    height: 100%;
    .search-bar-form-group {
      height: 100%;
      margin-bottom: 0 !important;
      .search-bar {
        position: relative;
        display: flex;
        height: 100%;
        margin-left: 40px;
        .search-bar-icon {
          position: absolute;
          top: 16px;
          left: 8px;
          font-size: 32px;
          color: #4a4a4c;
        }
        .search-bar-button {
          width: 100px;
          height: 100%;
          background-color: $mobileThemeLightColor;
          color: $white;
          border: 1px solid #4fafcd;
          border-color: $mobileThemeLightColor;
          border-radius: 0 4px 4px 0;
        }
      }
    }
    // }
    input {
      // height: 48px;
      height: 100%;
      padding-left: 48px;
      width: 304px;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid $inputBoxBordeColor;
    }
    img {
      margin-left: 10px;
    }
    ::placeholder {
      position: relative;
      color: $placeholderGrey;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px; /* 175% */
      letter-spacing: -0.3px;
    }
  }
}
