@import "assets/stylesheets/variables.scss";
@import "assets/stylesheets/mixins.scss";

.summary-page-wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  height: 100vh;
  padding: 0 15px 20px 15px;
  background-color: $slightGray;
}

.loading-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $disabledColor;
  background-color: $white;
}

.title-font-large {
  @include font-properties(32px, 400, 36px, $mobilePrimaryDark);
}

.title-font-regular {
  @include font-properties(16px, 600, 20px, $charcoalGray);
}

.text-font-small-black {
  @include font-properties(12px, 400, 14px, $black);
}

.text-font-small-grey {
  @include font-properties(14px, 400, none, $mobileDisabledGrey);
}

.loading-wrapper {
  position: relative;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  margin-top: -70px;
  opacity: 0.5;

  .loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 25px 25px;
    background-position: right center;
    background-repeat: no-repeat;
    z-index: 4;
  }
}
