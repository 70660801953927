@import "assets/stylesheets/variables.scss";

.header-section {
  display: flex;
  padding: 24px;
  align-items: center;
  flex: 1;
  .logo-section {
    .logo {
      width: fit-content;
      height: 100%;
      object-fit: contain;
      transition: width 0.5s;
      justify-self: flex-start;
    }
    transition: width 0.5s;
  }
  .header-open {
    width: 200px;
  }
  .header-close {
    width: 92px;
  }
  .search-bar-section {
    flex: 0.82;
    height: 100%;
  }
  .logout-section {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    margin-left: auto;
    .logout-text {
      color: $alertNegativeDark;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.3px;
    }
  }
}
