@import "../../assets/stylesheets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import "../../assets/stylesheets/fonts.scss";

.employee-signin-container {
  display: flex !important;
  justify-content: unset !important;

  @media screen and (max-width: 768px) {
    padding-top: 25px;  
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column !important;
  }

  @media screen and (min-width: 1025px) {
    position: relative;
  }

  @media screen and (max-width: 1024px) and (orientation: portrait){
    height: 100vh;
  }
}

.signin-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-top: -26px;
  min-height: calc(100vh - 60px);

  .login-form-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center; //inner container
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: calc(100vh - 60px);

    @media screen and (min-width: 1025px) {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
    }
    
    .employee-login-form {
      background-color: #0C0CA5;
      padding-right: 0 !important;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 52px 0;
      }
    }

    @media screen and (min-width: 376px) and (max-width: 1024px) {
      margin-top: 24px;
    }
    
    @media screen and (max-width: 1024px) {
      width: 100%;
      flex: 1;
    }

    .login-form-view {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 100%;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto; //inner content
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: stretch;
      min-width: 400px;

      @media screen and (max-width: 768px) {
        padding-right: 20px;
        padding: 8px 0;
      }

      .btn {
        min-width: 215px;
      }
    }

    .pre-login-footer-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
  }

  .image-and-process-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;

    .background-image-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      width: 100%;
      height: 245px;
      background-image: url(../../assets/images/hero_image_v2.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0;
      color: #ffffff;

      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        height: 264px;
      }

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        height: 522px;
      }

      .header-content {
        top: 30px;
        font-size: 30px;
        line-height: 34px;
        font-weight: 700;

        @media screen and (min-width: 1024px) and (max-width: 1440px) {
          font-size: 35px;
          line-height: 45px;
        }

        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 65px;
          line-height: 85px;
        }
      }
    }

    .portal-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto; //for inner arrangement
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: #002639;
      color: #fff;
      font-size: 12px;
      font-weight: 100;

      @media screen and (min-width: 1441px) and (max-width: 2560px) {
        font-size: 24px;
      }

      .patient-learn-more-container,
      .provider-learn-more-container,
      .combination-content {
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
      }

      .patient-learn-more-container {
        font-size: 14px;
        margin-bottom: 40px;
        .icon {
          position: absolute;
          left: 15px;

          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }

        .link {
          display: block;
          color: $primaryTextColor;
          font-weight: 500;
        }
      }

      .provider-learn-more-container {
        font-size: 14px;
        margin-top: 10px;

        .icon {
          position: absolute;
          left: 15px;

          &:before {
            @media screen and (min-width: 1441px) and (max-width: 2560px) {
              font-size: 48px;
            }
          }
        }

        .link {
          display: block;
          color: $primaryTextColor;
          font-weight: 500;
        }
      }

      .combination-content {
        font-size: 12px;
        text-align: center;
        margin-top: 15px;

        @media screen and (min-width: 1441px) and (max-width: 2560px) {
          font-size: 24px;
        }
      }
    }
  }

  .left-section-wrapper {
    display: flex;
    align-items: end;
    flex-direction: column;
    padding-left: 24px;

    @media screen and (max-width: 1024px) {
      padding-left: 8px;
      width: 100%;
      overflow: hidden;
    }

    .hero-content-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;

      .hero-image-container {
        position: relative;
        left: 40px;
        top: 15px;
        width: 450px;
        height: 450px;
        overflow: hidden;
        border-radius: 100%;  

        @media screen and (max-width: 767px) {
          position: relative;
          max-width: 200px;
          max-height: 200px;
          top: 0;
          left: 18px;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          position: relative;
          width: 350px;
          height: 350px;
          top: 10px;
          left: 18px;
        }

        @media screen and (min-width: 1025px) and (max-width: 1200px) {
          position: relative;
          width: 350px;
          height: 350px;
          top: 10px;
          left: 14px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .main-content-wrapper {
      position: relative;
      z-index: 2;
      margin-top: 37px;
      padding-right: 22px;
      
      @media screen and (max-width: 768px) {
        padding-left: 8px;
        margin-top: 24px;
      }
      
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        padding-left: 8px;
      }

      .hero-content {
        
        @media screen and (max-width: 375px) {
          margin-bottom: 0 !important;
        }

        @media screen and (max-width: 1024px) {
          margin-bottom: 24px;
        }

        p:first-child {
          margin-bottom: 24px;
        }
      }
    }

    .more-info-link {
      font-family: 'Avenir Next World', sans-serif;
      margin-top: 56px;

      @media screen and (max-width: 1024px) {
        margin: 0;
      }

      .helper-text {
        color: #292929;
        font-weight: 300;
        font-size: 24px;
        line-height: 28px;
        font-family: 'Avenir Next World', sans-serif;
        
        @media screen and (max-width: 767px) {
          margin-bottom: 2px;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-weight: 300;
          font-size: 20px;
          line-height: 24px;
        }

        @media screen and (max-width: 767px) {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .client-web-page {
        font-weight: 900;
        font-size: 36px;
        line-height: 43px;
        color: #1010EB;
        font-family: 'Avenir Next World', sans-serif;

        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
        }
      }
    }
  }

}

.login-form-container {

  .employee-login-form {
    width: 100%;

    .login-button {
      color: #000 !important;
      background-color: #fff !important;
      border: none;
      line-height: 12px;
      padding: 12px 16px;
      font-family: 'Avenir Next World', sans-serif;
      font-weight: 400;
      box-shadow: 0 5px 3px rgb(0,0,0,0.2);
      
      @media screen and (max-width: 767px) {
        line-height: 4px;
        font-weight: 300;
        color: #170F5F !important;
      }
    }

    .header {
      font-size: 40px;
      line-height: 48px;
      font-weight: 400;
      margin: 0px 0 44px 0;
      color: #FFFFFF;
      font-family: 'Avenir Next World', sans-serif;

      @media screen and (max-width: 767px) {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
      }
    }

    .sub-header {
      color: #FFFFFF;
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      margin: 0px 0 32px 0;
      font-family: 'Avenir Next World', sans-serif;
      
      @media screen and (max-width: 767px) {
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
      }

      @media screen and (max-width: 1400px) and (orientation: landscape) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
  
  .header {
    font-size: 30px;
    font-weight: 100;
    margin: 0px 0 20px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 10px 0;
      font-size: 26px;
    }
  }

  .sub-header {
    font-size: 20px;
    font-weight: 300;
    margin: 0px 0 20px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 10px 0;
      font-size: 20px;
    }
  }

  .logo-container {
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
    }

    img {
      width: 200px;
    }
  }

  .forgot-password {
    font-size: 14px;
    font-weight: 500;
    color: #00B2D0;
    margin-top: 10px;
    margin-bottom: 15px;

    span:hover {
      color: $defaultBtnHoverColor;
    }
  }

  .field-description {
    font-size: 12px;
    font-weight: 300;
    color: $gray;
  }

  .password-show {
    position: absolute;
    right: 10px;
    top: 15px;
    color: $primaryTextColor;
    cursor: pointer;

    &.disabled {
      color: $disabledColor;
      cursor: default;
    }
  }

  .term-and-condition-container {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .icon:before {
      font-size: 30px;
      top: 10px;
      left: -2px;
      color: $primaryTextColor;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        top: 5px;
      }
    }

    .term-and-condition-link {
      color: $primaryTextColor;
    }
  }

  .error-for-term {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 300;
    margin-left: 35px;
    color: $red;
  }

  .signup-link-container {
    margin-top: 20px;

    .btn {
      margin-left: 15px;
    }
  }
}

.pre-login-process-image-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: $preLoginImageBackground;
  width: 100%;
  height: 100%;
  margin-left: 20px;
}

.terms-and-conditions-modal {
  .modal-dialog {
    width: 400px;
    @media screen and (max-width: 420px) {
      width: 90%;
    }
  }
  #contained-modal-title{
    font-size: 18px;
    font-weight: 100;
    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  .term-and-condition-container {
    .icon:before {
      font-size: 24px;
      top: 8px;
      color: $primaryTextColor;
    }
    .term-and-condition-label {
      font-size: 14px;
      font-weight: 100;
      margin-left: 5px;
      margin-bottom: 1px;
    }

    .term-and-condition-link {
      color: $primaryTextColor;
      font-weight: 300;
      margin-bottom: 20px;
    }
    .btn-default {
      margin-bottom: 10px;
      background-color: #eeeeee;
      color: #888888;
      border-color: #eeeeee;
    }

    .btn-default:hover {
      background-color: #eeeeee !important;
      color: #888888 !important;
      border-color: #eeeeee !important;
    }

    .btn {
      min-width: 180px;
      height: 30px;
      font-size: 12px;
    }
  }
}

.mobile-app-download-view {
  margin-left: -18px;
  margin-top: 2%;
  width: 109%;
  background-color: #E4EFF4;
}

.patient-login-modal {
  .modal-dialog {
    width: 450px;
    @media screen and (max-width: 420px) {
      width: 90%;
    }
  }

  #contained-modal-title{
    font-size: 18px;
    font-weight: 100;
    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  .patient-login-container {
    .icon:before {
      font-size: 24px;
      top: 8px;
      color: $primaryTextColor;
    }

    .mobile-app-download-view-label{
      font-weight: bold;
    }

    .term-and-condition-link {
      color: $primaryTextColor;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .btn-default {
      margin-bottom: 10px;
      background-color: #eeeeee;
      color: #888888;
      border-color: #eeeeee;
    }

    .btn-default:hover {
      background-color: #eeeeee !important;
      color: #888888 !important;
      border-color: #eeeeee !important;
    }

    .btn {
      min-width: 180px;
      height: 30px;
      font-size: 12px;
    }
  }
}

.mobile-app-download-text{
  margin-left: 2%;
}

.mobile-app-download-client-image {
  margin-top: 2%;
  max-height: 120px;
  max-width: 120px;
  display: flex; align-items: center; justify-content: center;
  text-align: center;
}

.download-app-container{
  margin-top: -18px;
}

.mobile-appstore-download-image{
  height: 50px;
  width: 126px;
  margin-left: 6%;
}

// Mobile view
.mobile-view-container {
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 62px);
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 52px);
  }

  @media screen and (max-width: 375px) {
    min-height: calc(100vh - 50px);
  }

  .back-to-login {
    position: absolute;
    left: 20px;

    &.icon:before {
      color: $primaryTextColor;
    }
  }

  .logo-container {
    margin: 10px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin-bottom: 100px;
    }

    img {
      width: 100px;
    }
  }

  .header-text {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin: 10px 0;

    @media screen and (min-width: 736px) and (max-width: 768px) {
      margin: 50px;
    }

    span {
      display: block;
    }
  }

  .btn {
    margin: 5px 10px;
    min-width: 200px;
  }

  .forgot-password {
    font-size: 14px;
    font-weight: 500;
    color: #00B2D0;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .password-show {
    position: absolute;
    right: 10px;
    top: 15px;
    color: $primaryTextColor;
    cursor: pointer;

    &.disabled {
      color: $disabledColor;
      cursor: default;
    }
  }

  .error-for-term {
    display: block;
    clear: both;
    font-size: 12px;
    font-weight: 300;
    margin-left: 35px;
    color: $red;
  }

  // form section
  .signin-form-container {
    .page-title {
      text-align: center;
      background: #f5f5fa;
      padding: 20px 0;
      font-size: 30px;
      font-weight: 100;
      margin-top: 3px;
      margin-bottom: 50px;

      @media screen and (max-width: 320px) {
        margin-bottom: 20px;
      }
    }
  }

  .mobile-footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    div {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }

    background: $lightWhite;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 300;

    .contact-us {
      color: $primaryTextColor;
      margin-right: 30px;
      float: left;
    }

    .about-us {
      color: $primaryTextColor;
      margin-left: 10px;
      float: left;
    }

    .dot {
      margin-left: 10px;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: $gray;
      float: left;
    }

    .contact-number {
      color: $gray;
      margin-left: 10px;
      float: left;
    }
  }

  //new view changes
  .background-image-container {
    width: 100%;
    height: 236px;
    background-image: url(../../assets/images/mobile_hero_image_v2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    color: #ffffff;

    .header-content {
      top: 10px;
      font-size: 22px;
      line-height: 28px;
      font-weight: 700;
    }

    @media screen and (min-width: 568px) {
      background-image: url(../../assets/images/ipad_hero_image_v2.png);
      height: 315px;

      .header-content {
        top: 30px;
        font-size: 36px;
        line-height: 43px;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 320px) {
      height: 200px;

      .header-content {
        top: 20px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
      }
    }

    @media screen and (min-width: 768px) {
      height: 350px;
    }

    .signin-signup-button-container {
      margin-top: 40px;

      button {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        width: 150px;
        margin: 5px 0;
        padding: 0;

        @media screen and (max-width: 320px) {
          width: 120px;
        }
      }
    }
  }

  .portal-container {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    background: #000;
    color: #fff;
    padding: 20px 10px;
    font-size: 12px;
    font-weight: 100;

    .patient-learn-more-container {
      font-size: 14px;

      .signin-signup-button-row-container {
        .btn {
          width: 200px;
          height: 35px;
          margin: 5px 0;
        }
      }

      .row-sep {
        border-bottom: 1px solid $borderColor;
        margin-top: 15px;
        margin-bottom: 20px;
      }

      .icon {
        position: absolute;
        left: 15px;

        &:before {
          @media screen and (min-width: 1441px) and (max-width: 2560px) {
            font-size: 48px;
          }
        }
      }

      .link {
        display: block;
        color: $primaryTextColor;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .provider-learn-more-container {
      margin-top: 10px;
      font-size: 14px;

      .icon {
        position: absolute;
        left: 15px;

        &:before {
          @media screen and (min-width: 1441px) and (max-width: 2560px) {
            font-size: 48px;
          }
        }
      }

      .link {
        display: block;
        color: $primaryTextColor;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .separation-line {
      border-top: 1px solid #4a4a4a;
      margin: 20px 0;
    }

    .combination-content {
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
    }

    @media screen and (max-width: 320px) {
      padding: 10px 10px;

      .combination-content {
        margin-top: 10px;
      }
    }

    @media screen and (min-width: 736px) {
      padding: 20px 50px;
      font-size: 18px;

      .combination-content {
        font-size: 14px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 50px 50px;
      font-size: 18px;

      .provider-learn-more-container {
        margin-top: 50px;
      }

      .combination-content {
        margin-top: 50px;
      }
    }
  }

  .page-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 100%;

    .background-image-container,
    // .portal-container,
    .mobile-footer {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
  }
}

.back-button {
  color: $primaryTextColor;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 50px;
  margin-top: 30px;
}

.custom-video-container {
  .modal-dialog {
    width: auto;

    .modal-body {
      padding: 0;
      width: calc(78vw);
      height: calc(90vh);

      @media screen and (max-width: 768px) {
        width: calc(85vw);
        height: calc(60vh);
      }

      @media screen and (max-width: 768px) {
        width: calc(81vw);
        height: calc(31vh);
      }
    }

    #video {
      height: 100%;
      width: 100%;
    }
  }
}

.invite-code-footer {
  font-size: 12px;
  font-weight: 300;
}

.hero-heading-bold {
  font-size: 68px;
  line-height: 118px;
  font-weight: 400;
  color: $darkBlue;
  font-family: 'Avenir Next World', sans-serif;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 56px;
    line-height: 100px;
    font-weight: 400;
  }

  @media screen and (max-width: 850px) and (orientation: landscape) {
    font-size: 32px;
    line-height: 56px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1200px) and (orientation: landscape) {
    font-size: 56px !important;
    line-height: 100px !important;
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px) and (orientation: landscape) {
    font-size: 60px;
    line-height: 104px;
  }
}

.hero-heading {
  color: $darkBlue; 
  font-size: 28px;
  line-height: 60px;
  font-weight: 250;
  font-family: 'Avenir Next World', sans-serif;
  font-style: normal;

  .trade-mark-super-script {
    font-weight: 250;
    font-family: 'Avenir Next World', sans-serif;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 38px;
    font-weight: 250;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 28px;
    line-height: 60px;
    font-weight: 250;
  }

  @media screen and (max-width: 1400px) and (orientation: landscape) {
   font-size: 28px;
  }
}

.hero-content {
  color: $lightGrey; 
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  
    p {
      font-family: "Avenir Next World", sans-serif;

      .trade-mark-super-script {
        font-family: "Avenir Next World", sans-serif;
        font-weight: 300;

        @media screen and (min-width: 768px) {
          font-weight: 400;
        }
      }
    }

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 24px;
    line-height: 41px;
    font-weight: 400;
  }
}