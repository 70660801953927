@import "../../../assets/stylesheets/variables.scss";

.select-patient-container{
  width: 50%;
  margin: 30px auto;

  @media screen and (max-width: 572px) {
    width: 100%;
    margin-top: -20px;
  }

  @media screen and (min-width: 573px) and (max-width: 1023px) {
    width: 60%;
  }

  &.modaly {
    width:100%;
    min-height: 200px;
    margin: -10px auto;
  }

  .lbl-select-patient{
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    //color: $gray;
    @media screen and (max-width: 572px) {
      font-size: 16px;
    }
  }
  .lbl-select-patient-mobile {
    background: #F6F5FB;
    padding: 10px;
  }

  .select-patient-note{
    font-weight: 300;
    text-align: center;
    padding-top:10px;
    //color: $gray;
    @media screen and (max-width: 572px) {
      font-weight: 300;
      font-size: 12px;
      margin: 0 10px;
    }
  }

  .patient-container{
    margin-top: 20px;
    border: 1px solid $primaryTextColor;
    &.modaly {
      margin: 0px -20px;
      border: none;
    }
    @media screen and (max-width: 572px) {
      margin-top: 0px;
      border: none;
    }
  }
}
