@import "../../assets/stylesheets/variables.scss";

.home-page-container {
	margin: 0 15px;

	.dashboard-navigation-container {
		border: 1px solid $lightThemeColor;
		margin: 0 20px 20px 20px;
		border-radius: 4px;

		.dashboard-navigation-item {
			height: 40px;
			border-right: 1px solid $lightThemeColor;
			color: $primaryTextColor;
			cursor: pointer;
			@media screen and (max-width: 768px) {
				padding-left: 0px;
				padding-right: 0px;
			}

			@media screen and (min-width: 769px) and (max-width: 1048px) {
				padding-left: 10px;
				padding-right: 10px;
			}

			&:hover {
    		@keyframes exp {
		      from {background-color: rgba($hoverColor, 0.5);}
    			to {background-color: $hoverColor;}
		    }

		    animation-name: exp;
    		animation-duration: 1s;
    		background-color: $hoverColor;
    		color: #000;
    	}

			&:last-child {
				border-right: none;
			}
			&.disabled {
				color: $disabledColor;
				cursor: default;
			}

			.icon {
				position: relative;
				top: 2px;
			}

			.manage-patient-advocate-icon:before {
				top: 6px;
			}

			.manage-patient-advocate-label {
				@media screen and (max-width: 1008px){
					position: relative;
					top: -8px;
				}
			}
		}
	}
	.dashboard-body-containers {
		height: calc(100vh - 70px - 100px);
		padding: 0 20px;
	}
	.patient-followup-container {
		border: 1px solid #ccc;
		border-radius: 4px;
		width: calc(50% - 10px);
		height: calc(100% - 10px);
		// margin-right: 10px;
		float: left;

		@media screen and (max-width: 768px){
			width: 100%;
			height: calc(100% - 150px);
		}

		.glyphicon {
			margin:	0 20px;
		}
    	@media print {
      		width: 100%;
      		height: 80%;
    	}

		.patient-card-container {
			background: $lightWhite;
	    padding: 5px 0px 10px 0px;
	    height: calc(100% - 150px - 55px - 30px);
	    overflow: auto;
	    margin-top: 10px;
	    font-size: 12px;
	    line-height: 18px;

	    .card:hover {
	    	background: #fff;
	    }
		}

		.icon-font-a-stethoscope:before {
	    font-size: 15px;
	    top: 2px;
		}
    .providers-selector-pill {
      .providers-selector-pill--select {
        border: 1px solid $lightThemeColor;
        border-radius: 20px;
        background-color: $lightThemeColor;
        color: $white;
        font-size: 16px;
        .multi-select__control {
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
          }
          border: none;
          background-color: transparent;
          cursor: pointer;
          border-radius: 20px !important;
          .multi-select__placeholder {
            font-size: 15px;
            font-weight: 300;
            color: inherit;
          }
          .multi-select__indicators {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              display: inline-block;
            }
          }
          .multi-select__indicator {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              position: relative;
              top: 3px;
            }
            color: inherit;
            transition: none;
          }
        }
        .multi-select__indicator-separator {
          display: none;
        }
        .multi-select__menu {
          color: black;
          width: 100%;
          z-index: 9;
          .multi-select__option.multi-select__option--is-selected {
            label {
              span {
                &:nth-of-type(2) {
                  font-weight: 400;
                }
              }
            }
          }
          .multi-select__option {
            color: #676767;
            cursor: pointer;
            input, label {
              cursor: pointer;
            }
            label {
              display: flex;
              align-items: center;
              margin-left: 0 !important;
              span {
                &:first-of-type {
                  color: $lightThemeColor;
                  &:before {
                    display: flex;
                    font-size: 17px;
                  }
                }
                &:nth-of-type(2) {
                  font-weight: 300;
                  margin-left: 7px;
                }
              }
            }
          }
          footer {
            display: flex;
            justify-content: flex-end;
            padding: 8px 10px;
            border-top: 1px solid $lightThemeColor;

            .btn-primary {
              border-radius: 5px;
              height: auto;
              padding: 2px 20px;
              font-size: 14px;
              margin-right: 0;
            }
          }
        }
      }
      .providers-selector-pill--select.has-selected-items {
        background-color: $lightThemeColor;
        color:white;
      }
      .multi-select__value-container {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS styles go here */
          display: inline-block;
        }
        padding: 0 0 0 8px
      }
      .value-container--value-outer {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: inline;
        }
        justify-content: center;
        color: inherit;
        .value-container--value-label {
          flex: none;
        }
      }
    }
	}

	.track-patients-container {
		width: calc(50% - 10px);
		// margin-left: 10px;
		float: right;
		height: calc(100vh - 70px - 90px);

		@media screen and (max-width: 768px){
			width: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-left: 0px;
		}

		.track-patient-filter-container {
			border: 1px solid #ccc;
			border-radius: 4px;
			height: calc(100vh - 70px - 90px);

			.track-patients-results-container {
				overflow: auto;
				height: calc(100vh - 70px - 90px - 70px);

				.default, .custom {
					.icon:before {
						color: $themeColor;
					}
				}

				 .disabled {
					.icon:before {
						color: $lightGray;
					}
				}
				&.with-back-button{
					height: calc(100vh - 70px - 90px - 70px - 45px);
				}
			}
		}

		.add-patient-btn-container {
			width: 48%;
			float: left;
		}

		.survey-btn-container {
			width: 48%;
			float: right;
		}
	}

	.container-title {
		font-size: 20px;
		font-weight: 500;
		padding-bottom: 20px;
		padding-left: 20px;
		padding-top: 15px;
		position: relative;

		.add-custom-filters-btn {
			font-size: 16px;
			font-weight: 300;
			color: $primaryTextColor;
			position: absolute;
			right: 20px;
			top: 15px;
			cursor: pointer;

			&:hover {
		    color: $hoverTextColor;
		  }

			.icon {
				position: relative;
				top: 1px;

				&:before {
					font-size: 20px;
				}
			}
		}
	}

	.filter-back-button-container {
		padding-bottom: 25px;
		padding-left: 20px;
		padding-top: 20px;
		position: relative;

		.filters-back-btn {
			font-size: 16px;
			font-weight: 300;
			color: $primaryTextColor;
			position: absolute;
			top: 5px;
			bottom: 10px;
			cursor: pointer;

			&:hover {
		    color: $hoverTextColor;
		  }

			.icon {
				position: relative;
				top: 1px;

				&:before {
					font-size: 18px;
				}
			}
		}
	}

	.track-patients-container {
		.container-title {
			border-bottom: 1px solid $borderGreyColor;
		}
	}

	.no-padding {
		padding: 0px;
	}

	.search-container {
		width: 48%;
		margin: 0 1%;
		float: left;
		position: relative;
		margin-bottom: 20px;
	}

	.button-container {
		width: 48%;
		margin: 0 1%;
		float: left;
	}
}

.procedure-select, .location-select {
	// border: none;
	border-radius: 5px;
	box-shadow: none;
	border: 1px solid $lightGray;
	margin-bottom: 10px;
	font-size: 14px;
}

.procedure-select:focus, .location-select:focus {
	border-color: $lightGray;
	box-shadow: none;
}

.procedure-date-info {
	padding: 5px 10px;
	background-color: $infoBackground;
	font-size: 12px;
	margin: 15px 0;
	color: gray;
	border-radius: 4px;
}

.add-patient-modal {
	min-height: 320px;

	.modal-subheader-content {
		background: $hoverColor;
    margin-top: -21px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    padding-left: 5px;
    line-height: 40px;

    @media screen and (max-width: 767px){
    	margin-top: -15px;
    	margin-left: -15px;
    	margin-right: -15px;
    }

    .steps-count {
    	display: inline-block;
	    width: 25px;
	    line-height: 23px;
	    border: 1px solid $primaryTextColor;
	    text-align: center;
	    border-radius: 50%;
    }

    .active-step-count {
    	background: $activeColor;
    }

    .separation {
    	margin: 0px 3px;
    }
	}

	.icon-font-a-check-mark:before, .icon-font-a-check-box:before {
		color: $primaryTextColor;
	}
	.check-box-label {
		position: relative;
		top: -4px;
		left: 4px;
		font-size: 12px;
	}
}
.show-only-in-print {
  visibility: hidden;
  display: none;
}
.calender-disable-click {
	cursor: not-allowed !important;
	pointer-events: none;
}
@media print {
  body * {
    visibility: hidden;
	}
	.print-overflow-visible {
		overflow: visible;
	}
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
  .img-cell {
    width: 90px;
    height: 100px;
    padding: 10px 0px 10px 10px;
  }
  img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
  }
  .show-only-in-print {
    visibility: visible;
    display: inline;
  }
  .all-border {
	border: 1px solid $borderGreyColor;
  }
  .show-only-in-print.patient-procedure-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
    overflow: visible !important;
    .hide-in-print {
      display: none;
    }
  }
  .logo-in-print{
    padding: 10px 0px 10px 10px;
  }
  .index-in-print {
	  vertical-align: middle;
	  line-height: 90px;
	  padding: 0;
	  font-weight: 500;
	  font-size: 16px;
	  align-content: flex-end;
  }
  .patient-card-in-print {
	  padding: 0;
  }
  .topborder {
    border-top: 1px solid $borderGreyColor;
    margin: 10px 12px;
  }
}
