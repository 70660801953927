@import "assets/stylesheets/variables.scss";

.data-table-wrapper {
  display: grid;
  grid-template-rows: calc(100% - 80px) 80px;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.data-table-wrapper-no-data {
  height: 100%;
  width: 100%;
}
.table-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  .data-table {
    height: fit-content;
    max-height: 100%;
    width: 100%;
  }
  .no-order-text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 154px);
  }

  .table-header {
    height: 50px;
    background-color: $dataTableHeader;
    th {
      padding: 15px 20px;
    }
    .sorted-header {
      background-color: $tableHeaderBackground;
    }
  }
  .table-body {
    .table-row {
      cursor: pointer;
      border-bottom: 2px solid $lightGray;
      height: 64px;
      td {
        padding: 20px;
      }
    }
    .full-name-style {
      color: var(--Primary, $hcpReportButton);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.2px;
      text-decoration-line: underline;
    }
    .highlight {
      background-color: $dataTableHighlight;
    }
  }
}
.pagination-wrapper {
  padding: 24px;
}
