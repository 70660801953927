@import "../../../assets/stylesheets/variables.scss";

.mobile-date-picker-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.input-label {
  display: inline;
}
.required:before {
  content: " *";
  color: $mobileDangerColor;
  display: inline;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.MuiPickersToolbar-penIconButton {
  display: none !important;
}
