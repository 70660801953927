@import "../../../assets/stylesheets/variables.scss";

.back-navigation-container {
  font-weight: 100;
  padding: 16px 0;
  text-align: center;
  @media screen and (max-width: 572px) {
    font-size: 24px;
    margin-top: 0px;
  }
  .back-navigation-title {
    font-size: 34px;
    font-weight: 100;
    margin-top: 20px;
    @media screen and (max-width: 572px) {
      font-size: 22px;
    }
  }
  .back-navigation {
      color: $primaryTextColor;
      font-weight: 300;
      position: absolute;
      left: 20px;
      @media screen and (max-width: 572px) {
        font-size: 14px;
        margin-left: -6px;
        margin-top: -3px;
      }
      .back-navigation-text {
        font-size: 16px;
        margin-top: 1px;
        @media screen and (max-width: 572px) {
          font-size: 14px;
        }
      }
      .icon:before {
        font-size: 26px;
        top: 7px;
        left: 0px;
        @media screen and (max-width: 572px) {
          font-size: 24px;
        }
      }
  }
}
