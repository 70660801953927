@import "../../../assets/stylesheets/variables.scss";
.dashboard-container {
  margin-top: -18px;
  @media screen and (max-width: 572px) {
    overflow: scroll;
  }
}

.details-container {
  background: $bodyBackgroundColor;
  .tab-content-wrapper {
    overflow: auto;
    overflow-x: hidden;
    padding-left: 0px;
    padding-right: 0px;
    background: $white;
    min-height: calc(100vh - 205px);
  }

  .tab-content,
  .procedures-navigation-section,
  .questions-navigation-section,
  .tasks-navigation-section,
  .resources-navigation-section {
    background: $white;
  }

  .caregivers-list-container {
    background: $white;
  }
}

@media screen and (max-width: 768px) {
  .container-fluid {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    .dashboard-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
    }
    .dashboard-summary-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      margin: 0 -15px;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
  .select-procedure-main-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    .select-procedure-details-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      @media screen and (max-width: 425px) {
        padding: 0;
      }
    }
    .dashboard-summary-container {
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      align-self: auto;
      position: fixed;
      bottom: 0px;
      width: 100%;
    }
  }
}
