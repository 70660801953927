@import "../../../assets/stylesheets/variables.scss";
.loader-close-icon {
  position: absolute;
  top: -23px;
  right: 8px;
}

.modal-details {
  display: flex;
  flex-direction: column;
  padding: 0 30px 26px 30px;
  row-gap: 24px;
  .modal-content {
    display: flex;
    justify-content: center;
    box-shadow: none !important;
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    .modal-image {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .modal-with-no-icon {
      justify-content: center;
    }

    .modal-with-justified-content {
      flex-direction: column;
      gap: 24px;
    }
    .modal-text-and-icon {
      box-shadow: none !important;
      display: flex;
      column-gap: 24px;
      justify-content: flex-start;
      width: 100%;
      .modal-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .modal-form {
      width: 100%;
    }
  }

  .modal-content-header {
    color: $gray;
    font-size: 20px;
  }
  .modal-buttons {
    display: flex;
    column-gap: 24px;
    width: 100%;
  }
  .modal-buttons-stack {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
